import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
import CaptchaController, { Props, configJSON } from "./CaptchaController.web";

export default class Captcha extends CaptchaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
 
  handleVerify = (token:any) => {
     this.setState({token:token})
   
  }

 
  
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"} className="SiteKey">
            <form>
              
              
          
        <div >
       
           <GoogleReCaptcha data-test-id='verfy' onVerify={this.handleVerify} />
  
        
        </div>
      
              
            </form>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {};
// Customizable Area End
