import React from "react";
// Customizable Area Start
import { arrowbackward, whitefullwave, solidhalfcircle, arrowForwardIcon, dotGrid4x6Image, dotGrid6x6Image, desktopArrForwIcon, filledcheckbox } from "./assets";
import Captcha from "../../../blocks/captcha/src/Captcha.web";
import { Box, Typography, Button, styled } from "@mui/material";
import NavigationMenuWeb from "../../navigationmenu/src/NavigationMenu.web";


import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Customizable Area End

import MultipageFormsController, {
  Props,
  configJSON
} from "./MultipageFormsController";
import { ResolveCondition, ResolveConditionForMutyPara } from "../../utilities/src/CustomHelpers";
export default class DefaultZipCodeWeb extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <>
        <NavigationMenuWeb
          navigation={this.props.navigation}
          id={this.props.id}
          showHeaderOnly={true}
          isLandingPageShowLogo={false} // if logo is reruired then true

        />
      <Box sx={styles.mainContainer} className="dummy">
        <Box sx={styles.backIconContainer}>
          <Box component="img" data-testId="backIcon" sx={styles.backIcon} src={arrowbackward} alt="backIcon" onClick={() => this.handleNavigateLandingPage()} />
        </Box>
        <Box sx={styles.container}>
          <Box sx={styles.titleContainer}>
            <Box component="img" sx={styles.whitefullwave} src={whitefullwave} alt="whitefullwave" />
            <Box component="span" sx={styles.title}>{configJSON.defaultZipCodeTitle}</Box>
            <Box component="span" sx={styles.subtitle}>{configJSON.defaultZipCodeSubtitle1}</Box>
            <Box component="span" sx={styles.subtitle}>{configJSON.defaultZipCodeSubtitle2}</Box>
          </Box>
          <Box sx={styles.groupInputWithLabel} className="dummy">
            <Box component="img" sx={styles.dotGrid4x6Image} src={dotGrid4x6Image} alt="dotGrid4x6Image" />
            <Box component="img" sx={styles.dotGrid6x6Image} src={dotGrid6x6Image} alt="dotGrid6x6Image" />
            <Box sx={styles.inputwrapper}>
              <label style={styles.textlabel}>{configJSON.defaultZipCodeInputLabel}</label>
              <input name='zipcode' style={styles.textinput} data-test-id='txtInputzipcode' placeholder="e.g. 10001" value={this.state.zipcode} onChange={this.handleInputChange} />
              {
                ResolveConditionForMutyPara(this.state.zipcode !== "", !this.state.vaildZipcode, <Box component="span" sx={styles.errorDescription}>{configJSON.defaultZipCodeInputErrorDescription}</Box>)
              }
            </Box>
          </Box>
          {ResolveCondition(this.state.vaildZipcode, <div>
            <GoogleReCaptchaProvider reCaptchaKey={configJSON.sitekey}>

            <Captcha navigation={this.props.navigation} id={this.props.id} />
            </GoogleReCaptchaProvider>
            </div>, '')}
          <Box sx={styles.checkboxgroup}>
            <Box sx={styles.checkboxContainer} id="checkboxContainer">
              <CustomCheckboxLabel htmlFor="termsAndCondition">
              <input style={{ display: "none" }} name="termsAndConditionCheckbox" type="checkbox" id="termsAndCondition" checked={this.state.termsAndConditionCheckbox} onChange={this.handleCheckboxChange} />
                <CustomCheckboxInput data-testId="customCheckboxInput" />
                {configJSON.defaultZipCodeTermsAndConditionDescription}
              </CustomCheckboxLabel>
            </Box>
            <Box sx={styles.checkboxContainer}>
              <CustomCheckboxLabel htmlFor="acknowledgecheckbox">
              <input style={{ display: "none" }} name="acknowledgeCheckbox" type="checkbox" id="acknowledgecheckbox" checked={this.state.acknowledgeCheckbox} onChange={this.handleCheckboxChange} />
                <CustomCheckboxInput data-testId="customCheckboxInput2"/>
                {configJSON.defaultZipCodeAcknowledgeDescription}
              </CustomCheckboxLabel>
            </Box>
          </Box>
          <Box sx={styles.btnContainer}>
            <Button sx={styles.textBtn}>
              <Typography sx={styles.btnLabelText}>{configJSON.Submit}</Typography>
              <Box component="img" sx={styles.forwArrIcon} src={arrowForwardIcon} alt="rightArrow" />
              <Box component="img" sx={styles.deskforwArrIcon} src={desktopArrForwIcon} alt="rightArrow" />
            </Button>
          </Box>
          <Box sx={styles.solidhalfcirclecontainer}>
            <Box sx={styles.solidhalfcircleimgcontainer} className="dummy">
              <Box component="img" sx={styles.solidhalfcircle} src={solidhalfcircle} alt="solidhalfcircle" />
            </Box>
          </Box>
        </Box>
      </Box >
        <NavigationMenuWeb
          navigation={this.props.navigation}
          id={this.props.id}
          showFooterOnly={true}
          isLandingPageShowLogo={true}
        />
      </>
    );
  }
}

// Customizable Area Start

const styles = {
  mainContainer: {
    backgroundColor: "#F1ECE4",
    position: "relative" as const,
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 575px)": {
      width: "100%",
      position: "relative" as const,
    },
  },
  backIconContainer: {
    position: "absolute" as const,
    top: "2rem",
    left: "4rem",
    "@media (max-width: 575px)": {
      position: "absolute" as const,
      top: "2rem",
      left: "1rem",
    },
  },
  backIcon: {
    width: "20px",
    height: "20px",
    "@media (max-width: 575px)": {
      width: "20px",
      height: "20px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "2rem",
    marginTop: "2rem",
    width: "35rem",
    "@media (max-width: 575px)": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "5rem",
      marginTop: "3rem",
      width: "100%",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "0.5rem",
    justifyContent: "center",
    alignItems: "start",
    width: "50vw",
    margin: "2rem 0rem 0rem 0rem",
    position: "static" as const,
    "@media (max-width: 575px)": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "1rem",
      justifyContent: "center",
      alignItems: "start",
      width: "100%",
      position: "relative" as const,
      margin: "10rem 0rem 0rem 0rem",
    }
  },
  whitefullwave: {
    position: "absolute" as const,
    top: "2rem",
    right: 0,
    width: "25%",
    height: "auto",
    "@media (max-width: 575px)": {
      position: "absolute" as const,
      top: "-10rem",
      right: 0,
      width: "65%",
      height: "auto",
    }
  },
  title: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "3rem",
    fontWeight: "700",
    lineHeight: "3.5rem",
    letterSpacing: "-0.015em",
    textAlign: "left" as "left",
    "@media (max-width: 575px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "30px",
      fontWeight: "700",
      lineHeight: "40px",
      letterSpacing: "-0.005em",
      textAlign: "left" as "left",
      margin: "0rem 1rem",
      zIndex: "1",
    },
  },
  subtitle: {
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "1.2rem",
    fontWeight: "400",
    lineHeight: "1.7rem",
    textAlign: "left" as "left",
    "@media (max-width: 575px)": {
      color: "#706968",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      textAlign: "left" as "left",
      margin: "0rem 1rem",
      zIndex: "1",
    },
  },
  groupInputWithLabel: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "0.25rem",
    position: "static" as const,
    width: "20rem",
    "@media (max-width: 575px)": {
      display: "flex",
      flexDirection: "column" as "column",
      gap: "0.25rem",
      position: "relative" as const,
      width: "100%",
      alignItems: "flex-start",
    }
  },
  dotGrid4x6Image: {
    display: "none",
    position: "static" as const,
    right: "0rem",
    "@media (max-width: 575px)": {
      position: "absolute" as const,
      right: "0rem",
      display: "block",
    }
  },
  dotGrid6x6Image: {
    position: "absolute" as const,
    left: "4rem",
    "@media (max-width: 575px)": {
      display: "none",
      position: "static" as const,
    }
  },
  inputwrapper: {
    margin: "0rem 0rem 0rem 0rem",
    width: "20rem",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "0.25rem",
    "@media (max-width: 575px)": {
      margin: "0rem 1rem 0rem 1rem",
      width: "calc(100% - 2rem)",
      display: "flex",
      flexDirection: "column" as "column",
      gap: "0.25rem",
    }

  },
  textlabel: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "22px",
    textAlign: "left" as "left",
    "@media (max-width: 575px)": {
      color: "#524948",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "22px",
      textAlign: "left" as "left",
    }
  },
  textinput: {
    width: "100%",
    height: "44px",
    padding: "10px 8px",
    borderRadius: "8px",
    border: "1px solid #706968",
    outline: "none",
    opacity: "0px",
    backgroundColor: "#FFFFFF",
    color: "#706968",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left" as "left",
    zIndex: "1",
    position: "relative" as const,
  },
  errorDescription: {
    width: "100%",
    display: "flex",
    justifyContent: "start" as "start",
    color: "#DC2626",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "left" as "left",
  },
  checkboxgroup: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
  },
  checkboxContainer: {
    display: "flex",
    gap: "12px",
    padding: "0rem 0rem",
    "@media (max-width: 575px)": {
      padding: "0rem 1rem",
    },
  },
  btnContainer: {
    right: "1rem",
    bottom: "3rem",
    position: "absolute" as const,
    "@media (min-width: 576px)": {
      position: "static" as const,
      display: "block",
      margin: "0rem 0rem 0rem 0rem",
      height: "fit-content",
    },
  },
  textBtn: {
    textTransform: "inherit",
    display: "flex",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#524948",
    },
    "@media (min-width: 576px)": {
      width: "256px",
      backgroundColor: "#6699B1",
      borderRadius: "0.5rem",
      padding: "0.6rem 1rem",
      cursor: "pointer",
      color: "#F1ECE4",
      gap: "0.5rem",
      "&:hover": {
        backgroundColor: "#6699B1",
        color: "#F1ECE4",
      },
    },
  },
  btnLabelText: {
    color: "#524948",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "24px",
    textAlign: "left" as "left",
    "@media (min-width: 576px)": {
      color: "#F1ECE4",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
      textAlign: "left" as "left",
    },
  },
  forwArrIcon: {
    display: "none",
    "@media (max-width: 575px)": {
      display: "inherit",
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  deskforwArrIcon: {
    display: "inherit",
    width: "1rem",
    height: "1rem",
    "@media (max-width: 575px)": {
      display: "none",
    },
  },
  solidhalfcirclecontainer: {
    height: "10rem",
    "@media (max-width: 575px)": {
      height: "6rem"
    },
  },
  solidhalfcircleimgcontainer: {
    position: "absolute" as const,
    bottom: "0rem",
    left: "0rem",
  },
  solidhalfcircle: {
    width: "10rem",
  },
};

const CustomCheckboxInput = styled("span")`
    display:inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #706968;
    border-radius: 6px;
    margin-right: 12px;
    position: relative;
    flex-shrink: 0;

    input[type="checkbox"]:checked + &::after {
      content: "";
      background-image: url(${filledcheckbox});
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 1;
      transition: opacity 0.2s;
      width: 22px;
      height: 22px;
      position: absolute;
      top: -1px;
      left: -1px;
      flex-shrink: 0;
    }

    &::after {
    content: "";
    opacity: 0;
    width: 22px;
    height: 22px;
    position: absolute;
    top: -1px;
    left: -1px;
    flex-shrink: 0;
    },
`;

const CustomCheckboxLabel = styled("label")`
  display: inline-flex;
  color: #706968;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left as left;
`;

// Customizable Area End
