Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";
exports.defaultZipCodeTitle = "Let's begin!";
exports.defaultZipCodeSubtitle1 = "Enter your zip code to find out eligible candidates.";
exports.defaultZipCodeSubtitle2 = "Estimated time: 10 minutes";
exports.defaultZipCodeInputLabel = "Enter your Zip Code";
exports.defaultZipCodeInputErrorDescription = "Please enter a valid US zip code";
exports.defaultZipCodeTermsAndConditionDescription = "I accept the terms and conditions";
exports.defaultZipCodeAcknowledgeDescription = "I acknowledge that results might be inaccurate and independent research is recommended"
exports.Submit = "Submit";
exports.getQuestionStatsEndpoint='/bx_block_multipageforms2/user_profiles'
exports.getCategory='/bx_block_categories/categories'
exports.Email_Regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.sitekey='6LfRnl0qAAAAAA9DKwQhxmMGdhbi8gBLI6Jnza6c'


// Customizable Area End