import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import countrylist from "./CountryCodeList";

interface FormStructure{
  "id": string,
  "type": string,
  "attributes": {
      "id": 1,
      "name": string,
      "first_name": null,
      "last_name": null,
      "phone_number": null,
      "email": null,
      "gender": null,
      "country": null,
      "industry": null,
      "message": null
  }
}

interface FormStructure{
  "id": string,
  "type": string,
  "attributes": {
      "id": 1,
      "name": string,
      "first_name": null,
      "last_name": null,
      "phone_number": null,
      "email": null,
      "gender": null,
      "country": null,
      "industry": null,
      "message": null
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

const { baseURL: apiBaseUrl } = require("./../../../framework/src/config");

interface StateType {
  first_name: string;
  last_name: string;
  phone_number: string;
  selectedCountry: string;
  selectedGender: string;
  selectedPhoneCountry: string;
  email: string;
  message: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeSteps: number;
  countries: Array<object>;
  gender: Array<Gender>;
  country: Array<Country>;
  industry: Array<Industry>;
  selectedIndustry: string;
  token_local: string | null;
  showErrors: boolean;
  errorsData: string;
  successData: string;
  successDataMsg: string;
  isLoading: boolean;
  isSubmitted: boolean;
  formikData: FormikData;
  // Customizable Area Start

  selectedValue: string,
  navigationNum: number,
  numQut: number
  sliderValue:number
  snackbarOpenRental:boolean
  emailValidt:boolean;
  zipcode: string;
  vaildZipcode: boolean;
  modalOPen:boolean;

  acknowledgeCheckbox: boolean;
  termsAndConditionCheckbox: boolean;
  selectedBtn: any;
  questions:any
  simplequestions:any
  // Customizable Area End
}

interface FormikData {
  first_name: string;
  last_name: string;
  email: string;
  selectedPhoneCountry: string;
  phone_number: string;
  selectedGender?: string;
  selectedCountry?: string;
  message?: string;
  selectedIndustry?: string;
  countries: Array<object>;
}

interface CustomTypeData {
  contentType: string;
  method: string;
  body: {
    data: CustomTypeBodyData;
  };
  endPoint: string;
}

interface CustomTypeBodyData {
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    gender: string;
    country: string;
    industry: string;
    message: string;
  };
  type: string;
}

interface CustomTypeSubmitvalues {
  first_name: string;
  last_name: string;
  selectedPhoneCountry: string;
  phone_number: string;
  email: string;
  countries: Array<object>;
  selectedGender?: string;
  selectedCountry?: string;
  selectedIndustry?: string;
  message?: string;
}

interface CustomTypevalues {
  first_name: string;
  last_name: string;
  selectedPhoneCountry: string;
  phone_number: string;
  email: string;
  selectedGender: string;
  selectedCountry: string;
  selectedIndustry: string;
  message: string;
}

export interface CountriesList {
  Countries: Array<Countries>;
}

interface Countries {
  name: string;
  dial_code: string;
  code: string;
}

export interface Gender {
  label: string;
  value: string;
}

export interface Industry {
  label: string;
  value: string;
}
export interface Country {
  label: string;
  value: string;
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageFormsController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  getQuizApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      first_name: "",
      last_name: "",
      selectedPhoneCountry: "+91",
      phone_number: "",
      email: "",
      message: "",
      selectedCountry: "",
      selectedGender: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeSteps: 0,
      countries: countrylist,
      formikData: {
        selectedCountry: "1",
        selectedGender: "1",
        selectedIndustry: "education",
        first_name: "",
        last_name: "",
        email: "",
        selectedPhoneCountry: "",
        message: "",
        phone_number: "",
        countries: countrylist,
      },

      // Add more countries as neede
      gender: [
        { label: "Male", value: "1" },
        { label: "Female", value: "2" },
      ],
      country: [
        { label: "India", value: "1" },
        { label: "America", value: "2" },
        { label: "Canada", value: "3" },
        { label: "England", value: "4" },
      ],
      industry: [
        { label: "Education", value: "education" },
        { label: "Food", value: "food" },
        { label: "Marketing", value: "marketing" },
      ],
      selectedIndustry: "",
      token_local: localStorage.getItem("loginTokenBlock"),
      showErrors: false,
      errorsData: "",
      isLoading: false,
      successData: "",
      isSubmitted: false,
      successDataMsg: "",
      // Customizable Area Start
      selectedValue: "",
      navigationNum: 0,
      numQut: 0,
      sliderValue:0,
      snackbarOpenRental:false,
      zipcode: "",
      modalOPen:false,
      emailValidt:true,
      termsAndConditionCheckbox: false,
      acknowledgeCheckbox: false,
      vaildZipcode: false,
      selectedBtn: [],
      questions:[
        { qut: 'How strongly do you agree that the government should increase funding for border security measures, such as building a wall or enhancing surveillance?' ,
        sliderValue:0
      },
      { qut: 'Should taxes be decreased overall?' ,        sliderValue:0
    },
      
      ],
      simplequestions:[{ qut: 'How strongly do you agree that the government should increase funding for border security measures, such as building a wall or enhancing surveillance?' ,
      sliderValue:0
    },]
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderIdFailureCallBack = async (responseJson: string) => {
    alert("@@@@====>");
  };

  async componentDidMount(){
    this.getApi();
  }

  getApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      
    }; 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuizApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategory
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForQuestionStats = async (from: string, message: Message) => {
    if (
      this.getQuizApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        console.log(responseJson,'responseJson');
        
        responseJson.data.map((Cvl:any)=>{          
          
         this.setState({
          selectedBtn: [...this.state.selectedBtn,{
            value:Cvl.attributes.name,
            clk:false,
            id: Cvl.id
          }]
         })
        })   
      }
    }
  };

  // Api response
  async receive(from: string, message: Message) {
    this.handleResForQuestionStats(from,message)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        responseJson &&
        responseJson.errors &&
        apiRequestCallId === this.getOrderApiCallId
      ) {
        this.getOrderIdFailureCallBack(responseJson);
      }
      if (
        responseJson &&
        !responseJson.errors &&
        apiRequestCallId === this.getOrderApiCallId
      ) {
        const data = responseJson.data.attributes;
        this.setState({
          successDataMsg: configJSON.successMessage,
          successData: "",
          isSubmitted: true,
          activeSteps: 2,
          first_name: data.first_name,
          last_name: data.first_name,
          email: data.first_name,
          phone_number: data.phone_number,
          selectedGender: data.gender,
          selectedCountry: data.country,
          selectedIndustry: data.industry,
          message: data.message,
          formikData: {
            selectedCountry: "1",
            selectedGender: "1",
            selectedIndustry: "education",
            first_name: "",
            last_name: "",
            email: "",
            selectedPhoneCountry: "",
            message: "",
            phone_number: "",
            countries: countrylist,
          },
        });
      }
    }
    // Customizable Area End
  }

  multiPageFormSubmit = async (values: CustomTypevalues) => {
    const selectedCountryLabel = this.state.country.filter((item: Country) => {
      return item.value === values.selectedCountry ? item : null;
    });
    const selectedGenderLabel = this.state.gender.filter((item: Gender) => {
      return item.value === values.selectedGender ? item : null;
    });
    const selectedIndustryLabel = this.state.industry.filter(
      (item: Industry) => {
        return item.value === values.selectedIndustry ? item : null;
      }
    );

    const rawData = {
      data: {
        attributes: {
          first_name: `${values.first_name}`,
          last_name: `${values.last_name}`,
          phone_number: `${values.selectedPhoneCountry} ${values.phone_number}`,
          email: `${values.email}`,
          gender: selectedGenderLabel[0].label,
          country: selectedCountryLabel[0].label,
          industry: selectedIndustryLabel[0].value,
          message: `${values.message}`,
        },
        type: "email_account",
      },
    };

    this.getOrderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      body: rawData,
      endPoint: "/bx_block_multipageforms/user_profiles",
    });
  };
  apiCall = async (data: CustomTypeData) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  stepOnSubmit = (values: CustomTypeSubmitvalues) => {
    this.setState({
      formikData: values,
      activeSteps: this.state.activeSteps + 1,
    });
  };

  handleBack = () => {
    this.setState({ activeSteps: this.state.activeSteps - 1 });
  };

  getSteps() {
    return ["Step 1", "Step 2", "Complete"];
  }

  LoginPage = () => {
    this.props.navigation.navigate("EmailAccountLogin");
  };

  txtInputProps = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // Customizable Area Start

  handleChange = (event: any) => {
    if (this.state.selectedValue==''|| this.state.selectedValue!==event) {
      this.setState({ selectedValue: event });

    }
  };
  navigationNumChange = () => {
    if (this.state.selectedValue !== '') {
      if(this.state.selectedValue === 'Simplified Quiz'){
        this.setState({snackbarOpenRental:false, navigationNum: 3 });

      }else{

        this.setState({snackbarOpenRental:false, navigationNum: this.state.navigationNum + 1 });
      }
    }else{
      this.setState({snackbarOpenRental:true})
    }
  };
  handleSnackbarself=()=>{
    this.setState({snackbarOpenRental:false})
  }
  navigationNumChangeDec = () => {
    if(this.state.selectedValue === 'Simplified Quiz'){
      this.setState({ navigationNum: 0 });
      if(this.state.navigationNum == 0 && this.state.selectedValue=='Simplified Quiz'){
        this.props.navigation.navigate('ApiIntegration')
  
      } 
      
    }else
    if (this.state.navigationNum !== 0) {
      this.setState({ navigationNum: this.state.navigationNum - 1 });
    }else if(this.state.navigationNum == 0 && (this.state.selectedValue==''||this.state.selectedValue=='Simplified Quiz'||this.state.selectedValue=='Detailed Quiz')){
      this.props.navigation.navigate('ApiIntegration')

    } else {
      this.setState({ navigationNum: 0 });

    }

  };
  chngeQut = () => {
if(  this.state.selectedValue === 'Simplified Quiz'){
  if(  this.state.numQut + 1 === this.state.simplequestions.length  ){

    this.props.navigation.navigate('PrefferedCandidate')
  }
}
else
    if (this.state.numQut+1 === this.state.questions.length ) {
      

      this.props.navigation.navigate('PrefferedCandidate')
    } else
      if (this.state.numQut < 1) {
      
        this.setState({
          numQut: this.state.numQut + 1,
          sliderValue: 0

        });


      }
   
  }
  chngePrvQut = () => {
    if (this.state.numQut !== 0) {
      this.setState({ numQut: this.state.numQut - 1 ,sliderValue: 0});
    } else {
      this.setState({ numQut: 0 });

    }
  }
  handleSliderChange = (event:any, newValue:any) => {
    this.setState({ sliderValue: newValue }); 
  };
  selectedBtnChange = (data: any) => {
    const updatedButtons = this.state.selectedBtn.map((cvl: any) => {      
      if (cvl.id === data) {
        return {
          ...cvl, 
          clk: !cvl.clk
        };
      }
      return cvl;
    });
    this.setState({
      selectedBtn: updatedButtons
    });
  };

  modalOpen=()=>{
    this.setState({ modalOPen: true});
    
  }
  
  modalClose=()=>{
    this.setState({ modalOPen: false});
    
  }
  validateEmail(email:any) {
    const emailRegex = configJSON.Email_Regex;    
    if (emailRegex.test(email.target.value)) {
      this.setState({emailValidt:true})
    }else{
      this.setState({emailValidt:false})

    }
    
  }

  handleNavigateLandingPage = () => {
    this.props.navigation.navigate("LandingPage");
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<StateType, keyof StateType>);
    if (name === "zipcode") {
      const zipCodePattern = /^\d{5}(-\d{4})?$/;
      if (zipCodePattern.test(value)) {
        this.setState({ vaildZipcode: true });
      } else {
        this.setState({ vaildZipcode: false });
      }
    }
  }

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked } as unknown as Pick<StateType, keyof StateType>);
  };

 
  // Customizable Area End
}
